/* Let CRA handle linting for sample app */
import React, { Component } from 'react';
// import Spinner from 'react-spinner';
import classNames from 'classnames';
import EmojiPicker from 'emoji-picker-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccCore, { OpenTokSDK } from 'opentok-accelerator-core';
import AnnotationAccPack from 'opentok-annotation/dist/opentok-annotation.js';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import screensharingAccPack from 'opentok-screen-sharing';
import jwt from "jwt-decode";
import moment from 'moment';
import { endCall, updateSerialNumber, getParticipantsInCall, getRecordingStatus, sendTranscriptMsg, screenshotUpload, instantCallStart } from "../../services/meetingService.js";
import { vonageStartStopRecording, vonageStreamActions, vonageChangeLayoutClass } from "../../services/vonageService.js";
import { getTemplateData } from "../../services/machineService.js";
import html2canvas from 'html2canvas';
import AllApi from "../../Service/services";
import 'opentok-solutions-css';

import logo from './logo.svg';
import config from './config.json';
import './App.css';
import {
  removeAllCursors,
  removeCursor,
  startCursorListener,
  stopCursorListener,
  updateRemoteCursor,
  changeCursorElement,
  // updateCursorColor
} from './cursor.js';
import { message } from 'opentok-accelerator-core/dist/logging.js';
import { hours } from 'date-arithmetic';



var annotation = null;
var recording = null;
var screensharingLocal = null;
let callProperties = {
  insertMode: 'append',
  fitMode: "contain",
  resolution: "1920x1080",
  width: "100%",
  height: '100%',
  showControls: false,
  audioVolume:100,
  style: {
    buttonDisplayMode: 'off'
  }
};



const cursorUpdateMessageName = 'cursorPos';

const annotationOptionsOld = {
  screensharing: false,
  canvasContainer: "annotationContainerCustom",
  externalWindow: '.App-annotation-parent-container',
  absoluteParent: ".app-annotation-container"
}

const archiving = {
  startURL: 'https://webhook.site/a8e9e93c-0718-4f84-aedf-f3586d6a816d',
  stopURL: 'https://webhook.site/a8e9e93c-0718-4f84-aedf-f3586d6a816d',
}


const annotationOptions = {
  screensharing: false,
  canvasContainer: ".app-annotation-container",
  cobrowsingImage: null,
  mobileInitiator: true,
  absoluteParent: '#cameraSubscriberContainer',
  toolbarItems: ['pen', 'colors', 'shapes', 'text', 'clear', 'undo'],
  toolbarShapes: ['rectangle', 'oval', "arrow"],
  colors: ["#FF00F7", "#0F0F0F", "#FFFFFF", "#00FFFF"],
}

const screenshareOptions = {
  extensionID: 'ahnoholjhfihligoooocomnjogeiogfg',
  screenSharingContainer: "screenPublisherContainer",
  annotation: false,
  externalWindow: false,
  dev: true,
  localScreenProperties: {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    display: "none",
    showControls: false,
    style: {
      buttonDisplayMode: 'on',
      display: "none"
    },
    videoSource: 'window',
    fitMode: 'contain' // Using default
  },
}

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled, screenSharePublishing, pointerStarted, localRecordingEnabled, streamMap, subscribers, isLeading, annotationStarted } = state;

  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraPublishers = meta ? meta.publisher.camera : 0;
  const activeAnnotationButton = (activeCameraSubscribers) ? activeCameraSubscribers : true;
  const activeSerialNumberInput = (activeCameraSubscribers || activeCameraPublishers) ? (activeCameraSubscribers || activeCameraPublishers) : false;
  const activeSerialNumberInputData = (activeCameraSubscribers || activeCameraPublishers) ? (activeCameraSubscribers || activeCameraPublishers) : false;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  const totalStreams = (state.streamMap != null) ? Object.keys(state.streamMap).length : 0;

  console.log("waitingforParticipants,Active!"+active);
  console.log("waitingforParticipants,totalStreams"+totalStreams);
  
  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    videoControls: classNames('video-call-control', { hidden: !active }),
    localAudioClass: classNames('local-audio-class', { hidden: !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('local-video-class', { hidden: !active, muted: !localVideoEnabled }),
    localRecordingClass: classNames('local-recording-class', { enabled: !localRecordingEnabled }),
    localRecordingTimer: classNames('pulse-effect', { hidden: !localRecordingEnabled }),
    localScreenShareClass: classNames('local-screenshare-class', { hidden: !active, started: screenSharePublishing }),
    localPointerClassClass: classNames('local-pointer-class', { hidden: !active, started: pointerStarted }),
    localCallClass: classNames('', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
    screenPublisherClass: classNames('video-container', { hidden: true }),
    annotationPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    activeAnnotationButtonClass: classNames('annotation-wrap', { hidden: !active || !(activeCameraSubscribers || activeCameraPublishers) }),
    activeSerialNumberInput: classNames('serial-number-wrap', { hidden: !active || !activeSerialNumberInput }),
    waitingForParticipant: classNames('', { hidden: totalStreams > 1 }),
    activeSerialNumberInputData: (activeCameraSubscribers || activeCameraPublishers) ? (activeCameraSubscribers || activeCameraPublishers) : false,
    cameraSubscriberClass: classNames('video-container1', { hidden: !active || !(activeCameraSubscribers || activeCameraPublishers) } , 
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive, },
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { 'cursornone': pointerStarted },
      { 'borderBlue': annotationStarted },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active }),
    annotationSubscriberClass: classNames('video-container', { hidden: true || !viewingSharedScreen || !active }),
  };
};



const startCallMask = (start, localVideoEnabled, toggleLocalVideo, localAudioEnabled, toggleLocalAudio, isLeading, disableJoinButton) => {



  return <>


    <div className="permission-screen-wrap">
      <div className="permission-screen">
        <div className="permission-screen-left">
          <div className="img-wrap">
            <a href="#">
              <img src="assets/images/logo-desktop.png" alt="logo-desktop" className="desktop-view" />
              <img src="assets/images/logo-mobile.png" alt="logo-mobile" className="mobile-view" />
            </a>
          </div>
        </div>
        <div className="permission-screen-right">
          <div className="permission-screen-info">
            <div className="title">Choose your audio and video options</div>
            <div className="control-btn-wrap">
              <button disabled={disableJoinButton} className={!localAudioEnabled ? "audio-btn" : "audio-btn active"} onClick={toggleLocalAudio}>
                {!localAudioEnabled && <img src="assets/images/audio-mute.svg" className="svg mute" alt="audio-mute" />}
                {localAudioEnabled && <img src="assets/images/audio-unmute.svg" className="svg unmute" alt="audio-unmute" />}
              </button>
              {<button disabled={disableJoinButton} className={(!isLeading || !localVideoEnabled) ? "video-btn cursor-disabled" : "video-btn active"} onClick={toggleLocalVideo}>
                {(!isLeading || !localVideoEnabled) && <img src="assets/images/video-mute.svg" className="svg mute" alt="video-mute" />}
                {(!isLeading || localVideoEnabled) && <img src="assets/images/video-unmute.svg" className="svg unmute" alt="video-unmute" />}
              </button>}
            </div>
            <div className="sub-title">Click on start call button below to start the call</div>
            {!isLeading && <div className="sub-title">Your camera will be off during this session</div>}
            <div className="start-call-cta-wrap">
              <button disabled={disableJoinButton} onClick={() => start()}>
                {!disableJoinButton ? 'Join Now' : 'Loading'}
              </button>
            </div>
            <div className='joining_information'>
              <div className="title">Tips for your Session:</div>
              <ul>
                <li>
                  <span className="sub-title">Headphones: </span>Use them for better audio and to reduce background noise.
                </li>
                <li>
                  <span className="sub-title">Chat: </span>Use chat in loud environments for clear communication
                </li>
                <li>
                  <span className="sub-title">Camera: </span>The active camera is always from the user who scheduled or sent the call link. Rear-facing is default; switch if needed.
                </li>
                <li>
                  <span className="sub-title">Pointer &amp; Tools: </span>Highlight issues using the pointer and annotation tools.
                </li>
                <li>
                  <span className="sub-title">Record &amp; Save: </span>Record sessions or take screenshots for reports or future reference
                </li>
              </ul>


            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

let otSDK;
let otCore;
let timer;
let hh, mm, ss;
const api = new AllApi();

class App extends Component {



  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      name: "",
      userData: {},
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      localRecordingEnabled: false,
      isRecordingInitiator: false,
      serialNumber: "",
      isLeading: false,
      annotationStarted: false,
      selectedPointerColor: (localStorage.getItem("selected_color") != null) ? localStorage.getItem("selected_color") : '#0F0F0F',
      screenShareStarted: false,
      screenSharePublishing: false,
      recordingAnnotationPublisher: null,
      screenSharingPublisher: null,
      emojiPickerOpen: false,
      pointerStarted: false,
      messagesCount: 0,
      annotationPublisher: {},
      annotationStream: {},
      videoWidth: 0,
      activeChat: false,
      videoHeight: 0,
      session: null,
      meetingId: "",
      annotation: null,
      archiveID: null,
      streamMap: null,
      customStreamMap: {},
      localPublisherId: null,
      disableJoinButton: false,
      disableInternalCallButtons: false,

    };

    // window.addEventListener('storage', (e) => this.changeRecordingClasses(e));


    this.startCall = this.startCall.bind(this);
    this.toggleChatWindow = this.toggleChatWindow.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.screenshot = this.screenshot.bind(this);
    this.changeSerialNumber = this.changeSerialNumber.bind(this);
    this.submitSerialNumber = this.submitSerialNumber.bind(this);
    this.stopAnnotation = this.stopAnnotation.bind(this);
    this.endCallButtonClick = this.endCallButtonClick.bind(this);
    this.isJsonString = this.isJsonString.bind(this);
    this.screenShareToggle = this.screenShareToggle.bind(this);
    this.toggleRecording = this.toggleRecording.bind(this);
    this.messageCountChange = this.messageCountChange.bind(this);
    this.pointerStart = this.pointerStart.bind(this);
    this.pointerSelect = this.pointerSelect.bind(this);
    this.changeCursorColor = this.changeCursorColor.bind(this);
    this.changePointerColor = this.changePointerColor.bind(this);
    this.appendEmojiToMessage = this.appendEmojiToMessage.bind(this);
    this.openEmojiBox = this.openEmojiBox.bind(this);
    this.closeEmojiBox = this.closeEmojiBox.bind(this);
    this.takeScreenshot = this.takeScreenshot.bind(this);
    this.changeRecordingClasses = this.changeRecordingClasses.bind(this);

    this.textAreaRef = React.createRef();
    
  }



  async componentDidMount() {
    const { isRecordingInitiator } = this.state;
    config.sessionId = localStorage.getItem("vonage_session_id");
    config.token = localStorage.getItem("vonage_token");
    const token = localStorage.getItem("token");
    const meeting_id = localStorage.getItem("vonage_meetingId");

    this.setState({ session: config.sessionId });

    let user = jwt(token);

    let res1 = getParticipantsInCall(meeting_id).then((response) => {
      if (response.status === 200) {
        if (response.data.user.id === user.user_id) {
          this.setState({ isLeading: true });
        }
      }
    });


    otSDK = new OpenTokSDK(config);


    const otCoreOptions = {
      credentials: {
        apiKey: "06e31ffb-26e6-41a0-bb2b-e57d1f562b14",
        sessionId: localStorage.getItem("vonage_session_id"),
        token: localStorage.getItem("vonage_token"),
      },
      packages: ['screenSharing'],
      screenSharing: {
        extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
        annotation: false,
        externalWindow: false,
        dev: true,
        screenProperties: {
          insertMode: 'append',
          width: '100%',
          height: '100%',
          showControls: false,
          style: {
            buttonDisplayMode: 'off',
          },
          videoSource: 'window',
          fitMode: 'contain' // Using default
        },
      },
    }
    otCore = new AccCore(otCoreOptions);

    console.log("otCore", otCore);
    console.log("otSDK", otSDK);

    const session = otSDK.session;


  }

  changeCursorColor(color) {

    // Encode the SVG with the new fill color
    const svg = `
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.23109 21.8329L0.93293 3.11023C0.508124 1.60905 1.97239 0.380386 3.377 1.05941L20.8952 9.52823C22.4824 10.2955 22.3252 12.6047 20.6616 12.9609L13.5842 14.4763C13.0337 14.5942 12.5852 14.9705 12.3735 15.4921L9.6522 22.1989C9.01251 23.7754 6.71112 23.5292 6.23109 21.8329Z" fill="${color}" stroke="white"/>
        </svg>
    `;

    // Encode the SVG into a data URL
    const encodedSVG = encodeURIComponent(svg);
    // Update the cursor style with the new color
    document.getElementById('cameraSubscriberContainer').style.cursor = `url("data:image/svg+xml,${encodedSVG}") 0 0, auto`;

  }

  changePointerColor(color) {
    const { selectedPointerColor, userData, pointerStarted } = this.state;
    this.setState({ pointerStarted: true })
    otSDK.signal("pointerColorChange", { "userId": userData.user_id, color: color });
    this.changeCursorColor(color);
    const callback = (x, y, width, height, xpt, ypt) => {
      const { selectedPointerColor } = this.state;
      otSDK.signal("pointerLocation", { "x": x, "y": y, "width": width, "height": height, "xpt": xpt, "ypt": ypt, "userName": userData.firstName + ' ' + userData.lastName, "userId": userData.user_id, color: selectedPointerColor });
    };
    startCursorListener(callback);
    localStorage.setItem("selected_color", color);
    this.setState({ selectedPointerColor: color })
    var pointerMenuOpener = document.querySelector('.pointer-menu-opener');
    var pointerMenu = document.querySelector('.pointer-menu');
    pointerMenuOpener.classList.remove('active');
    pointerMenu.classList.remove('active');
    // updateCursorColor(userData.user_id, color)
  }

  openEmojiBox() {
    const { emojiPickerOpen } = this.state;
    this.setState({ emojiPickerOpen: !emojiPickerOpen })
  }
  closeEmojiBox() {
    this.setState({ emojiPickerOpen: false })
  }

  appendEmojiToMessage(data) {
    var elem = document.getElementById('msg_input');
    elem.value += data.emoji;
  }

  changeRecordingClasses(){
    const { customStreamMap } = this.state;
    const items = [];
    console.log("Recording Change", customStreamMap);
    const result = [];
    const entries = Object.entries(customStreamMap);
    const hasScreen = entries.some(([_, value]) => value === "screen");
    const hasAnnotation = entries.some(([_, value]) => value === "annotation");
    const hasAudio = entries.some(([_, value]) => value === "audio");
    const hasVideo = entries.some(([_, value]) => value === "camera");
    const hide = entries.some(([_, value]) => value === "hide");

    for (const [id, type] of entries) {
        let layoutClassList = ["bar"]; // Default class

        if (hasScreen && type === "screen") {
            layoutClassList = ["focus"];
        } else if (hasAnnotation) {
            layoutClassList = type === "annotation" ? ["focus"] : ["bar"];
        } else if (hide) {
          layoutClassList = ["inactive","none","hidden","hide"];
        } else if (!hasScreen && hasAudio && hasVideo) {
            layoutClassList = type === "camera" ? ["focus"] : ["bar"];
        }

        result.push({ id, layoutClassList });
    }

    const dataLayout = {
      sessionID: otSDK.session.sessionId,
      layouts: result
    }
    
    vonageChangeLayoutClass(dataLayout).then((response) => {
    });
  }

  pointerStart() {

    const { userData, pointerStarted, selectedPointerColor } = this.state;

    if (pointerStarted == false) {
      this.setState({ pointerStarted: true })
      this.changeCursorColor(selectedPointerColor);
      const callback = (x, y, width, height, xpt, ypt) => {
        const { selectedPointerColor } = this.state;
        otSDK.signal("pointerLocation", { "x": x, "y": y, "width": width, "height": height, "xpt": xpt, "ypt": ypt, "userName": userData.firstName + ' ' + userData.lastName, "userId": userData.user_id, color: selectedPointerColor });
      };
      startCursorListener(callback);
    } else {

      this.setState({ pointerStarted: false })
      stopCursorListener();
      removeCursor(userData.user_id);
      document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
      otSDK.signal("removePointerLocation", { "userId": userData.user_id });
    }

  }

  pointerSelect() {

    var pointerMenuOpener = document.querySelector('.pointer-menu-opener');
    var pointerMenu = document.querySelector('.pointer-menu');

    pointerMenuOpener.classList.toggle('active');
    pointerMenu.classList.toggle('active');


    document.addEventListener('click', function (event) {
      if (!pointerMenuOpener.contains(event.target) && !pointerMenu.contains(event.target)) {
        pointerMenuOpener.classList.remove('active');
        pointerMenu.classList.remove('active');
      }
    });
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  async startCall() {

    var { session, streamMap, localAudioEnabled, localVideoEnabled, isLeading } = this.state;

    this.setState({ disableJoinButton: true });

    const token = localStorage.getItem("token");
    let user = jwt(token);
    const meeting_id = localStorage.getItem("vonage_meetingId");

    await otSDK.connect().then(async (res) => {
      this.setState({ active: true, connected: true });
      const meeting = {
        meeting_id: meeting_id,
      };
      instantCallStart(meeting);

      getRecordingStatus(meeting_id).then((response) => {

        if (response.status === 200) {

          const recordingEnabled = !(Object.keys(response.data.data).length === 0 && response.data.data.constructor === Object);

          if (recordingEnabled == true) {

            if (response.data.data != null && response.data.data.createdBy.id == user.user_id) {
              this.setState({ isRecordingInitiator: true });
            }
            this.setState({ localRecordingEnabled: recordingEnabled });
          }


        }
      });




    }).then((res2) => {
      var totalSeconds = 0;
      setTime();
      timer = setInterval(setTime, 1000);

      function setTime() {
        ++totalSeconds;
        hh = pad(Math.floor(totalSeconds / 3600));
        mm = pad(Math.floor(totalSeconds % 3600 / 60));
        ss = pad(Math.floor(totalSeconds % 3600 % 60));

        document.getElementById('timer_counter').innerHTML = hh + ":" + mm + ":" + ss
      }

      function pad(val) {
        var valString = val + "";
        if (valString.length < 2) {
          return "0" + valString;
        } else {
          return valString;
        }
      }

      this.setState({ connected: true, userData: user, name: user.firstName + ' ' + user.lastName, meetingId: meeting_id });
      this.setState({ active: true });
    });

    const getChatTimeFormat = (date) => {
      var d = new Date();
      var hour = d.getHours() == 0 ? 12 : (d.getHours() > 12 ? d.getHours() - 12 : d.getHours());
      var min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
      var ampm = d.getHours() < 12 ? 'AM' : 'PM';
      return hour + ':' + min + ' ' + ampm;

    }

    const subscribeToStream = stream => {
      
      var { screenSharePublishing, selectedPointerColor, userData } = this.state;
      if (streamMap && streamMap[stream.id]) { return; }
      const type = stream.videoType;
      let streamData;
      
      if (this.isJsonString(stream.name)) {
        streamData = JSON.parse(stream.name);
        // alert(streamData.platform)
        if (typeof streamData.platform != "undefined" && streamData.platform === 'web') {
          document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";
        } else {
          document.getElementById('cameraSubscriberContainer').style.aspectRatio = "9/16";
        }
      } else {
        streamData = stream.name;
      }

      if (streamData.name === "Sharing_Screen") {
        this.state.customStreamMap[stream.id] = "screen";
      }else if(streamData.name === "Publisher View" && streamData.leading == false ){
        this.state.customStreamMap[stream.id] = "audio";
      }else if(streamData.name === "Publisher View" && streamData.leading == true ){
        this.state.customStreamMap[stream.id] = "camera";
      }else if(streamData.name === "record_annotations_Screen"){
        this.state.customStreamMap[stream.id] = "annotation";
      }else if(streamData.name === "Annotation_Screen"){
        this.state.customStreamMap[stream.id] = "hide";
      }

      this.setState({ customStreamMap: this.state.customStreamMap});
      console.log("customStreamMap",this.state.customStreamMap);
      
      if (streamData.name === "Sharing_Screen") {
        if (screenSharePublishing === true) {
          // screensharingLocal.end();
          otSDK.unpublish(this.state.screenSharingPublisher);
          this.setState({ screenSharingPublisher: null });
          this.changeRecordingClasses();
          const data = {
            "action": "add",
            "archiveID": this.state.archiveID,
            "streamID": stream.streamId,
            "sessionID": session,
          }

          vonageStreamActions(data).then((response) => {
          });
          this.setState({ screenShareStarted: false, screenSharePublishing: false })
        }
        if (this.state.pointerStarted) {
          this.setState({ pointerStarted: false })
          stopCursorListener();
          removeCursor(this.state.userData.user_id);
          otSDK.signal("removePointerLocation", { "userId": this.state.userData.user_id });
          document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
        }
        this.setState({ screenShareStarted: true })
      }
      if (streamData.name === "record_annotations_Screen") {
        otSDK.subscribe(stream, 'cameraPublisherContainer', { ...callProperties })
        return;
      }

      if (streamData.name === "Annotation_Screen") {

        if (this.state.screenSharingPublisher != null) {
          otSDK.unpublish(this.state.screenSharingPublisher);
          this.setState({ screenSharingPublisher: null });
          this.setState({ screenShareStarted: false, screenSharePublishing: false })
        }

        if (this.state.pointerStarted) {
          this.setState({ pointerStarted: false })
          stopCursorListener();
          removeCursor(userData.user_id);
          otSDK.signal("removePointerLocation", { "userId": userData.user_id });
          document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
        }


        // callProperties.width = stream.videoDimensions.width+"px";
        // callProperties.height = stream.videoDimensions.height+"px";
        this.loadCanvas('canvas');
        // otSDK.subscribe(stream, `app-annotation-container`,{...callProperties})
        // .then(() => this.setState(otSDK.state()));
        annotation = new AnnotationAccPack(otSDK);

        annotationOptions.colors.forEach(function (item, i) {
          if (item === selectedPointerColor) {
            annotationOptions.colors.splice(i, 1);
            annotationOptions.colors.unshift(item);
          }
        });

        annotation.start(otSDK.session, annotationOptions);
        annotation.linkCanvas("subscriber", annotationOptions.absoluteParent, annotationOptions);

        this.setState({ annotationStarted: true })
        annotation.resizeCanvas();
        annotation.setLineWidthDefault(4); 
      }


      if (typeof streamData.leading != "undefined" && streamData.leading == false && streamData.name !== "Sharing_Screen") {

        if (typeof streamData.name != "undefined" && streamData.name == "Publisher View") {
          document.getElementById("participant_name").innerHTML = streamData.userName;
          document.getElementById("participant_name").classList.remove("hidden");
        }
        otSDK.subscribe(stream, `cameraPublisherContainer`, callProperties).then((res) =>{
          this.setState(otSDK.state())
        });
      } else {

        if (typeof streamData.name != "undefined" && streamData.name == "Publisher View") {
          document.getElementById("participant_name").innerHTML = streamData.userName;
          document.getElementById("participant_name").classList.remove("hidden");
        }

        otSDK.subscribe(stream, `cameraSubscriberContainer`, callProperties).then((data) =>{

          // otSDK.enableSubscriberAudio(data.streamId, true),
          // otSDK.enableSubscriberVideo(data.streamId, true), 
          if (streamData.name === "Annotation_Screen") {
            const self = this;
            setTimeout(function() { 
              const imgData = data.getImgData();
              console.log("imgData",imgData)
              var img = document.createElement("img");
              var annotationWidth = document.getElementById('cameraSubscriberContainer');
              img.setAttribute("src", "data:image/png;base64," + imgData);
              img.setAttribute("id", "annotation_image");
              img.setAttribute("width", data.videoWidth());
              img.setAttribute("height", data.videoHeight());
              document.getElementById("app-annotation-container").setAttribute('width', annotationWidth.scrollWidth);
              document.getElementById("app-annotation-container").setAttribute("height", annotationWidth.scrollHeight);
              document.getElementById("app-annotation-container").appendChild(img);
              self.createCanvasStream(img);

            }, 1500);
            // this.setState({annotationPublisher: data});
            
          }
          

          this.setState(otSDK.state())
        })

        otSDK.subscribe(stream, 'main-video-container', { ...callProperties, mirror: false, subscribeToAudio: false, fitMode: "cover" })

      }
      this.changeRecordingClasses();
    };

    otSDK.session.streams.forEach(subscribeToStream);
    let pointerData;
    // Subscribe to new streams and update state when streams are destroyed

    otSDK.on({
      'streamCreated': ({ stream }) => {subscribeToStream(stream)},
      "signal:pointerLocation": ({ data }) => {

        pointerData = JSON.parse(data)

        if (pointerData.userId !== this.state.userData.user_id) {
          if (!(pointerData.xpt < 0 || pointerData.ypt < 0 || pointerData.xpt > 100 || pointerData.ypt > 100)) {
            updateRemoteCursor(pointerData.userId, pointerData.userName, pointerData.xpt, pointerData.ypt, pointerData.color)
          } else {
            document.getElementById(`cursor-${pointerData.userID}`).style.display = "none";
          }
        }


      },
      "signal:pointerColorChange": ({ data }) => {

        pointerData = JSON.parse(data)
        if (pointerData.userId !== this.state.userData.user_id) {
          changeCursorElement(pointerData.userId, pointerData.color)
        }


      },
      "signal:removePointerLocation": ({ data }) => {

        pointerData = JSON.parse(data)
        removeCursor(pointerData.userId)
      },
      "signal:recording": (data) => {
        this.setState({ localRecordingEnabled: JSON.parse(data.data).isActive })
        if (JSON.parse(data.data).isActive === true) {

        } else {
          // clearInterval(timer);

        }
      },
      'streamDestroyed': ({ stream }) => {
        var { localRecordingEnabled, recordingAnnotationPublisher, customStreamMap } = this.state;
        let streamData;
        console.log("streamDestroyed",stream);
        delete this.state.customStreamMap[stream.id];
        console.log("customStreamMap",this.state.customStreamMap);
        this.changeRecordingClasses();
        if (this.isJsonString(stream.name)) {
          streamData = JSON.parse(stream.name);
          if (typeof streamData.platform != "undefined" && streamData.platform === 'web') {
            document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";
          } else {
            document.getElementById('cameraSubscriberContainer').style.aspectRatio = "9/16";
          }
        } else {
          streamData = stream.name;
        }

        if (streamData.name === "Annotation_Screen") {
          annotation.end();
          document.getElementById('app-annotation-container').remove();
          // if(localRecordingEnabled && (recordingAnnotationPublisher != null)){
          //   otSDK.unpublish(this.state.recordingAnnotationPublisher);
          //   this.setState({recordingAnnotationPublisher: null})
          // }
          this.setState({ annotationStarted: false })
        } else if (streamData.name === "Sharing_Screen") {
          this.setState({ screenShareStarted: false });
        }

        this.setState(otSDK.state())
      },
      'signal:stop-annotation': ({ stream }) => {
        var { localRecordingEnabled, recordingAnnotationPublisher } = this.state;
        // this.setState({annotationStarted : false})
        if (Object.keys(this.state.annotationPublisher).length > 0) {
          this.setState({ annotationStarted: false })
          otSDK.unpublish(this.state.annotationPublisher);
          this.setState({ annotationPublisher: {} });
          annotation.end();
          document.getElementById('app-annotation-container').remove();
          if(localRecordingEnabled && (recordingAnnotationPublisher != null)){
            otSDK.unpublish(this.state.recordingAnnotationPublisher);
            this.setState({recordingAnnotationPublisher: null})
          }
        }
      },
      'signal:start_annotation': ({ stream }) => {
        var { isLeading } = this.state;
        if (isLeading === true) {
          this.screenshot();
        }
      },

      'signal:textChat': (event) => {
       
        console.log("event",event)
      var msg = document.createElement('div');
      var msgHistory = document.getElementById("chatHistory");
      const chatBy = event.from.connectionId === otSDK.session.connection.connectionId ? 'sender-chat' : 'receiver-chat';

      console.log("this.state", this)

      if (!document.getElementsByClassName('video-calling-screen-left')[0].classList.contains('active')) {
        document.getElementById('badgeIcon').style = "display:block";
        this.messageCountChange();
      }

      if (event.data != null) {
        if (chatBy == 'sender-chat') {

          const name = JSON.parse(event.data).name;
          const message = JSON.parse(event.data).message;

          msg.setAttribute("class", 'sender-chat');
          msg.setAttribute("user", event.from.connectionId);


          msg.innerHTML = `
                          <div class="info">
                            <ul>
                              <li>
                                <div class="info-box">
                                    <div class="author">`+ name + `</div>
                                    <div class="description">`+ message + `</div>
                                    <div class="time">`+ getChatTimeFormat(event.from.creationTime) + `</div>
                                </div>
                            
                              </li>
                            </ul>
                          </div>`;
          msgHistory.appendChild(msg);


        } else {


          const name = JSON.parse(event.data).name;
          const message = JSON.parse(event.data).message;
          msg.setAttribute("user", event.from.connectionId);
          msg.setAttribute("class", 'receiver-chat');
          msg.innerHTML = `<div class="info">
                            <ul>
                              <li>
                                <div class="info-box">
                                    <div class="author">`+ name + `</div>
                                    <div class="description">`+ message + `</div>
                                    <div class="time">`+ getChatTimeFormat(event.from.creationTime) + `</div>
                                </div>
                                
                              </li>
                            </ul>
                          </div>
                          `;

          msgHistory.appendChild(msg);
        }
      }

      msg.scrollIntoView(); 
      }



    });




    otSDK.session.on("signal:disconnect-room", async function (event) {

      if (event.from.connectionId !== otSDK.session.connection.connectionId) {
        const token = localStorage.getItem("token");
        const obj = { Authorization: "Bearer " + token };
        await api.patchAxiosCalls("PATCH", "/v1/user/isFree", { isFree: true }, obj);
        otSDK.disconnect();
        stopCursorListener();
        document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
        // this.setState({ active: false });

        localStorage.removeItem("vonage_session_id");
        localStorage.removeItem("vonage_token");
        localStorage.removeItem("vonage_meetingId");
        localStorage.removeItem("selected_color");
        window.location.assign('/dashboard/main');
      }
    });




    // Publish local camera streamconst events

    if (isLeading) {
      const publisherObject = {};
      publisherObject.name = "Publisher View";
      publisherObject.platform = "web";
      publisherObject.leading = true;
      publisherObject.userName = ((user.firstName) ? user.firstName : '') + ' ' + ((user.lastName) ? user.lastName : '');
      callProperties.name = JSON.stringify(publisherObject);
      // document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";

      console.log(publisherObject);
      console.log("Updated localAudioEnabled",this.state.localAudioEnabled)
      const pubb = otSDK.publish('cameraSubscriberContainer', { ...callProperties, mirror: false, publishAudio: this.state.localAudioEnabled, publishVideo: this.state.localVideoEnabled })
        .then((publisher) => {
          publisher.on({
            accessDenied: function accessDeniedHandler(event) {
              // The user has denied access to the camera and mic.
              alert("No Audio and Video Devices found or allow access to the Audio and Video from browser setting and refresh the page to start the call")
            }
          });

          otSDK.enablePublisherAudio(this.state.localAudioEnabled);
          otSDK.enablePublisherVideo(this.state.localVideoEnabled);
          otSDK.subscribe(publisher.stream, 'main-video-container', { ...callProperties, mirror: false, publishVideo: localVideoEnabled, fitMode: "cover", })
          this.state.customStreamMap[publisher.streamId] = "camera";
          this.changeRecordingClasses();
          this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id, customStreamMap: this.state.customStreamMap}));
        }).catch(error => alert("No Audio and Video Devices found or allow access to the Audio and Video from browser setting and refresh the page to start the call"));

    } else {
      const publisherObject = {};
      publisherObject.name = "Publisher View";
      publisherObject.platform = "web";
      publisherObject.leading = false;
      publisherObject.userName = ((user.firstName) ? user.firstName : '') + ' ' + ((user.lastName) ? user.lastName : '');
      callProperties.name = JSON.stringify(publisherObject);
      console.log(publisherObject);
      console.log("Updated localAudioEnabled",this.state.localAudioEnabled)
      // document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";
      otSDK.publish('cameraPublisherContainer', { ...callProperties, mirror: false, publishAudio: this.state.localAudioEnabled, publishVideo: false })
        .then((publisher) => {
          publisher.on({
            accessAllowed: function (event) {
              alert("accessAllowed");
            },
            accessDenied: function accessDeniedHandler(event) {
              // The user has denied access to the camera and mic.
              alert("accessDenied")
            }
          });
          otSDK.enablePublisherAudio(localAudioEnabled);
          otSDK.enablePublisherVideo(false);
          this.state.customStreamMap[publisher.streamId] = "audio";
          this.changeRecordingClasses();
          this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id, customStreamMap: this.state.customStreamMap}));
        }).catch(error => alert(error));
    }


    console.log("customStreamMap", this.state.customStreamMap);

  }




  async toggleLocalAudio() {
    const { localPublisherId, publishers, localAudioEnabled } = this.state;
    const enabled = !localAudioEnabled;
    
    this.setState((prevState) => ({
      localAudioEnabled: !prevState.localAudioEnabled,
    }), () => {
      otSDK.enablePublisherAudio(enabled);
    });
    // this.setState({ localAudioEnabled: enabled });
  }

  messageCountChange() {
    const { messagesCount } = this.state;
    this.setState({ messagesCount: messagesCount + 1 });
  }

  async toggleLocalVideo() {
    // const { localPublisherId, publishers, localVideoEnabled, isLeading } = this.state;
    if (this.state.isLeading) {
      const enabled = !this.state.localVideoEnabled;
      
      this.setState((prevState) => ({
        localVideoEnabled: !prevState.localVideoEnabled
      }), () => {
        otSDK.enablePublisherVideo(this.state.localVideoEnabled);
        console.log("Updated localVideoEnabled:", this.state.localVideoEnabled);
      });
      // this.setState({ localVideoEnabled: enabled });
    }

  }

  createCanvasStreamCopy = (img) => {
    const { annotationStarted } = this.state;
    var canvas = document.getElementById('app-annotation-container');
    var annotationWidth = document.getElementById('cameraSubscriberContainer');
    var ctx = canvas.getContext("2d");

    var wrh = img.width / img.height;
    console.log(img.width + "  " + img.height);
    console.log(annotationWidth.scrollWidth + "  " + annotationWidth.scrollHeight);
    var newWidth = annotationWidth.scrollWidth;
    var newHeight = newWidth / wrh;
    if (newHeight > annotationWidth.scrollHeight) {
      newHeight = annotationWidth.scrollHeight;
      newWidth = newHeight * wrh;
    }


    console.log(wrh);
    console.log(newWidth + "  " + newHeight);
    ctx.drawImage(img, 0, 0, newWidth, newHeight);

    // var img1 = document.getElementById("annotation_image");
    (function loop() {

      setTimeout(loop, 1000 / 10)
      if (document.getElementById('app-annotation-container') == undefined) {
        return;
      }
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      // ctx.drawImage(img, cx, cy, cw, ch,  iwidth, iheight, w, h);


    })();

    return canvas.captureStream(10).getVideoTracks()[0];

  }


  createCanvasStream = (img) => {
    const { annotationStarted } = this.state;
    var canvas = document.getElementById('app-annotation-container');
    var annotationWidth = document.getElementById('cameraSubscriberContainer');
    var ctx = canvas.getContext("2d");

    let scale = Math.min(annotationWidth.scrollWidth / img.width, annotationWidth.scrollHeight / img.height);
    let width = img.width * scale;
    let height = img.height * scale;
    let x = annotationWidth.scrollWidth / 2 - width / 2;
    let y = annotationWidth.scrollHeight / 2 - height / 2;


    ctx.drawImage(img, x, y, width, height);


    // var img1 = document.getElementById("annotation_image");
    (function loop() {

      setTimeout(loop, 1000 / 10)
      if (document.getElementById('app-annotation-container') == undefined) {
        return;
      }
      ctx.drawImage(img, x, y, width, height);
      // ctx.drawImage(img, cx, cy, cw, ch,  iwidth, iheight, w, h);


    })();

    return canvas.captureStream(10).getVideoTracks()[0];

  }


  stopAnnotation() {

    const { annotationStarted, annotationPublisher, localRecordingEnabled, recordingAnnotationPublisher } = this.state;
    
    console.log("annotationPublisher",annotationPublisher)
    if (Object.keys(annotationPublisher).length > 0) {
      this.setState({ annotationStarted: false })
      otSDK.unpublish(annotationPublisher);
      this.setState({ annotationPublisher: {} });
      annotation.end();
      document.getElementById('app-annotation-container').remove();
      if (localRecordingEnabled && (recordingAnnotationPublisher != null)) {
        otSDK.unpublish(this.state.recordingAnnotationPublisher);
        this.setState({ recordingAnnotationPublisher: null })
      }
    } else {
      otSDK.signal("stop-annotation", null);

    }

  }

  loadCanvas(type) {
    if (document.getElementById('app-annotation-container')) {
      document.getElementById('app-annotation-container').remove()
    }

    var canvas = document.createElement(type);
    const div = document.getElementById('cameraSubscriberContainer');
    canvas.id = "app-annotation-container";
    // canvas.width = 442;
    // canvas.height = 786;
    // canvas.style.zIndex   = 99;
    canvas.style.position = "absolute";
    div.appendChild(canvas)
  }

  screenshot() {

    const { isLeading, localRecordingEnabled, recordingAnnotationPublisher, userData, archiveID, session, } = this.state;
    this.setState({ pointerStarted: false })
    stopCursorListener();
    removeCursor(userData.user_id);

    if (this.state.screenSharingPublisher != null) {
      otSDK.unpublish(this.state.screenSharingPublisher);
      this.setState({ screenSharingPublisher: null });
      this.setState({ screenShareStarted: false, screenSharePublishing: false })
    }

    otSDK.signal("removePointerLocation", { "userId": userData.user_id });
    document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
    if (isLeading === false) {
      otSDK.signal("start_annotation", null);
    } else {
      this.setState({ disableInternalCallButtons: true })
      this.loadCanvas('canvas');
      this.setState({ annotationStarted: true })
      var w, h, offsetX, offsetY;
      var annotationWidth = document.getElementById('cameraSubscriberContainer');

      var imgData = Object.values(otSDK.state().publishers.camera)[0].getImgData();
      const height = Object.values(otSDK.state().publishers.camera)[0].videoHeight();
      const width = Object.values(otSDK.state().publishers.camera)[0].videoWidth();

      var img = document.createElement("img");

      img.setAttribute("src", "data:image/png;base64," + imgData);
      img.setAttribute("id", "annotation_image");
      img.setAttribute("width", width);
      img.setAttribute("height", height);
      document.getElementById("app-annotation-container").setAttribute('width', annotationWidth.scrollWidth);
      document.getElementById("app-annotation-container").setAttribute("height", annotationWidth.scrollHeight);
      document.getElementById("app-annotation-container").appendChild(img);

      const publisherObject = {};
      publisherObject.name = "Annotation_Screen";
      publisherObject.platform = "web";
      publisherObject.leading = isLeading;
      publisherObject.userName = userData.firstName + ' ' + userData.lastName;

      otSDK.publish('cameraPublisherContainer', {
        // c1 is the text canvas. Substitute c2 if you want to use the overlay canvas.
        videoSource: this.createCanvasStream(img),
        // videoSource: this.createCanvasStream(img),
        width: img.width,
        publishAudio: false,
        height: img.height,
        name: JSON.stringify(publisherObject),
        showControls: false,
        insertMode: 'append',
        // videoSource: "window"
      })
        .then((publisher) => {
          this.state.customStreamMap[publisher.streamId] = "hide";
          this.setState({ annotationPublisher: publisher });
          this.setState(Object.assign({}, otSDK.state(), {}));
        }).catch(error => console.log(error));

      annotation = new AnnotationAccPack(otSDK);
      annotation.start(otSDK.session, annotationOptions);
      annotation.linkCanvas("subscriber", annotationOptions.absoluteParent, annotationOptions);
      annotation.setLineWidthDefault(4);
      annotation.resizeCanvas();
      this.setState({ disableInternalCallButtons: false });

      if (localRecordingEnabled && (recordingAnnotationPublisher == null)) {

        const publisherObject = {};
        publisherObject.name = "record_annotations_Screen";
        publisherObject.platform = "web";
        publisherObject.leading = false;
        publisherObject.userName = userData.firstName + ' ' + userData.lastName;

        otSDK.publish('screenPublisherContainer', {
          // c1 is the text canvas. Substitute c2 if you want to use the overlay canvas.
          videoSource: this.createCanvasStream1(),
          // videoSource: this.createCanvasStream(img),
          // width: img.width,
          publishAudio: false,
          // height: img.height,
          name: JSON.stringify(publisherObject),
          showControls: false,
          insertMode: "append"
          // videoSource: "window"
        }).then(async (publisher) => {
          const data = {
            "action": "add",
            "archiveID": archiveID,
            "streamID": publisher.streamId,
            "sessionID": session,
            "screenType": "annotation"
          }

          vonageStreamActions(data);
          this.setState({ recordingAnnotationPublisher: publisher });
          this.state.customStreamMap[publisher.streamId] = "annotation";
          this.changeRecordingClasses();
          console.log("customStreamMap",this.state.customStreamMap)
          this.setState(Object.assign({}, otSDK.state(), {customStreamMap: this.state.customStreamMap}));

          
          publisher.on("streamDestroyed", ({stream}) => {
            const { customStreamMap } = this.state;
            this.setState({ recordingAnnotationPublisher: null });
            delete customStreamMap[stream.id];
            console.log("customStreamMap",customStreamMap)
            this.changeRecordingClasses();
          });
        }).catch(error => console.log(error));
      }
    }

  }

  toggleChatWindow() {
    this.setState({ activeChat: !this.state.activeChat, messagesCount: 0 })
    document.getElementById('badgeIcon').style = "display:none"

  }

  async sendMessage() {
    const { name, meetingId, userData } = this.state;
    var data = document.getElementById('msg_input');

    if (data.value.length > 0) {
      otSDK.signal("textChat", { "name": name, "message": data.value });
      const timestampData = {
        "timestamp": moment().format("DD MMM YYYY HH:mm:ss"),
        "message": data.value
      }
      document.getElementById('msg_input').value = '';
      sendTranscriptMsg(meetingId, timestampData);

    }

  }

  enterPresses(e) {
    this.textAreaRef.current.style.height = "auto"; 
    const maxHeight = 3 * 24; // Assuming each line is ~24px tall
    // this.textAreaRef.current.style.height = this.textAreaRef.current.scrollHeight + "px";
    this.textAreaRef.current.style.height = Math.min(this.textAreaRef.current.scrollHeight, maxHeight) + "px";

    if (e.key === 'Enter' || e.keyCode === 13) {
      this.sendMessage();
    }
  }



  changeSerialNumber(event) {
    this.setState({ serialNumber: event.target.value })
  }

  async submitSerialNumber() {

    const data = {
      meetingId: this.state.meetingId,
      serialNumber: this.state.serialNumber,
    };

    let res = await updateSerialNumber(data).then((response) => {
      if (response.status === 200) {
        document.getElementById('serial-number-update-message-id').classList.remove("hidden");
      }
    });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string

  }


  async takeScreenshot() {

    const { meetingId } = this.state;
    this.setState({ disableInternalCallButtons: true })
    html2canvas(document.getElementById("cameraSubscriberContainer")).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);

      var byteString;
      if (image.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(image.split(',')[1]);

      // separate out the mime component
      var mimeString = image.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }


      var formData = new FormData();
      formData.append("images", new Blob([ia], { type: mimeString }), 'image.jpg');
      const screenshotBox = document.getElementById('screenshot-box');
      const screenshotImage = document.getElementById('screenshot-image');

      screenshotImage.src = image;

      screenshotBox.classList.add('show');

      setTimeout(() => {
        screenshotBox.classList.remove('show');
      }, 5000);

      screenshotUpload(meetingId, formData).then((res) => {

      })
      this.setState({ disableInternalCallButtons: false });

    });
    const screenshotBox1 = document.getElementById('screenshot-box');
    setTimeout(() => {
      screenshotBox1.classList.remove('show');
    }, 5500);


  }


  createCanvasStream1() {

    var can = document.getElementById('app-annotation-container');
    var ctx = can.getContext('2d');
    var can2 = document.getElementById('opentok_canvas');
    var ctx2 = can2.getContext('2d');

    var canvas = document.createElement('canvas');
    canvas.id = "app-annotation-container2";

    canvas.width = can2.width;
    canvas.height = can2.height;
    var ctx3 = canvas.getContext('2d');
    
    ctx3.drawImage(can, 0, 0, can.width, can.height, 0, 0, canvas.width, canvas.height);
    ctx3.drawImage(can2, 0, 0);
    ctx3.fillStyle = "#ff000044";


    // var img1 = document.getElementById("annotation_image");
    (function loop() {

      setTimeout(loop, 1000 / 10)
      if (document.getElementById('app-annotation-container') == undefined) {
        return;
      }
      ctx3.drawImage(can, 0, 0, can.width, can.height, 0, 0, canvas.width, canvas.height);
      ctx3.drawImage(can2, 0, 0);
      // ctx.drawImage(img, cx, cy, cw, ch,  iwidth, iheight, w, h);


    })();

    return canvas.captureStream(10).getVideoTracks()[0];


  }

  async toggleRecording() {

    // annotation.resizeCanvas();    

    var { localRecordingEnabled, session, meetingId, isRecordingInitiator, session, annotationStarted, userData } = this.state;



    if (!localRecordingEnabled && annotationStarted) {

      const publisherObject = {};
      publisherObject.name = "record_annotations_Screen";
      publisherObject.platform = "web";
      publisherObject.leading = false;
      publisherObject.userName = userData.firstName + ' ' + userData.lastName;
      otSDK.publish('cameraPublisherContainer', {
        // c1 is the text canvas. Substitute c2 if you want to use the overlay canvas.
        videoSource: this.createCanvasStream1(),
        // videoSource: this.createCanvasStream(img),
        // width: img.width,
        publishAudio: false,
        // height: img.height,
        name: JSON.stringify(publisherObject),
        showControls: false,
        insertMode:"append"
        // videoSource: "window"
      })
        .then(async (publisher) => {
          this.state.customStreamMap[publisher.streamId] = "annotation";
          this.changeRecordingClasses();
          console.log("customStreamMap",this.state.customStreamMap)
          this.setState({ recordingAnnotationPublisher: publisher });
          this.setState(Object.assign({}, otSDK.state(), {}));
          publisher.on("streamDestroyed", ({stream}) => {
            const { customStreamMap } = this.state;
            this.setState({ recordingAnnotationPublisher: null });
            delete customStreamMap[stream.id];
            console.log("customStreamMap",customStreamMap)
          });
          
        }).catch(error => console.log(error));


    }

    // if(localRecordingEnabled && (recordingAnnotationPublisher != null)){
    //   otSDK.unpublish(this.state.recordingAnnotationPublisher);
    //   this.setState({recordingAnnotationPublisher: null})
    // }
    const data = {
      "action": !localRecordingEnabled ? "start" : "stop",
      "sessionID": otSDK.session.sessionId,
      "meetingID": meetingId,
      "layoutType": "horizontalPresentation",
      "streamMode":"auto"
    }
    let res = await vonageStartStopRecording(data).then((response) => {

      if (response.status === 200) {

        if (!localRecordingEnabled) {
          this.setState({ "archiveID": response.data.data.achieve_id })

          console.log("annotation",this.state.annotationPublisher);
          Object.keys(otSDK.state().streamMap).filter((item) => item !== this.state.annotationPublisher.streamId).map(elem => {
            console.log("streram",elem);
            const data = {
              "action": "add",
              "archiveID": response.data.data.achieve_id,
              "streamID": elem,
              "sessionID": session
            }

            vonageStreamActions(data).then((response) => {
            });
          })
          this.changeRecordingClasses();
        } else {
          this.setState({ "archiveID": null })
          if (this.state.recordingAnnotationPublisher != null) {
            otSDK.unpublish(this.state.recordingAnnotationPublisher);
            this.setState({ recordingAnnotationPublisher: null })
          }
        }


        otSDK.signal("recording", { isActive: !localRecordingEnabled });
        this.setState({ localRecordingEnabled: !localRecordingEnabled, isRecordingInitiator: !isRecordingInitiator });
      } else {

      }
    });


  }
  async screenShareToggle() {
    var { session, pointerStarted, annotationStarted, isLeading, userData, screenSharingPublisher, archiveID, localRecordingEnabled, userData } = this.state;




    if (screenSharingPublisher == null) {

      const publisherObject = {};
      publisherObject.name = "Sharing_Screen";
      publisherObject.platform = "web";
      publisherObject.leading = isLeading;
      publisherObject.userName = userData.firstName + ' ' + userData.lastName;
      screenshareOptions.localScreenProperties.name = JSON.stringify(publisherObject);
      // screensharingLocal = new screensharingAccPack({...screenshareOptions, session:otSDK.session, "accPack":otCore});
      // screensharingLocal.start({publishAudio:false, name: JSON.stringify(publisherObject)})
      var screenpublisher = await otSDK.publish('screenPublisherContainer', { ...screenshareOptions.localScreenProperties, mirror: false, publishAudio: false })
        .then((publisher) => {
          this.state.customStreamMap[publisher.streamId] = "screen";
          this.changeRecordingClasses();
          this.setState(Object.assign({}, otSDK.state(), {customStreamMap: this.state.customStreamMap}));
          
          if (pointerStarted) {
            this.setState({ pointerStarted: false })
            stopCursorListener();
            removeCursor(userData.user_id);
            otSDK.signal("removePointerLocation", { "userId": userData.user_id });
            document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
          }
          // console.log(publisher);
          
          if (annotationStarted) {
            this.stopAnnotation()
          }
          this.setState({ screenSharingPublisher: publisher });
          this.setState({ screenShareStarted: true, screenSharePublishing: true })

          publisher.on("streamDestroyed", ({stream}) => {
            const { customStreamMap } = this.state;
            this.setState({ screenShareStarted: false, screenSharePublishing: false })
            this.setState({ screenSharingPublisher: null });
            delete customStreamMap[stream.id];
            this.changeRecordingClasses();
          });

          return publisher;
        }).catch(error => this.setState({ screenSharingPublisher: null }));


          if (localRecordingEnabled) {
            const data = {
              "action": "add",
              "archiveID": archiveID,
              "streamID": screenpublisher.streamId,
              "sessionID": session
            }

            vonageStreamActions(data);
          }
         
        console.log("screenpublisher",screenpublisher);

    } else {
      const { customStreamMap } = this.state;
      delete customStreamMap[this.state.screenSharingPublisher.id];
      otSDK.unpublish(this.state.screenSharingPublisher);
      this.setState({ screenShareStarted: false, screenSharePublishing: false })
      this.setState({ screenSharingPublisher: null });
      
    }



  }

  async endCallButtonClick() {

    const { meetingId } = this.state;

    otSDK.signal("disconnect-room", null);

    if (meetingId) {
      const data = {
        meeting_id: meetingId,
        meeting_status: (Object.keys(otSDK.state().streamMap).length <= 1) ? 2 : 1,
      };

      const token = localStorage.getItem("token");
      const obj = { Authorization: "Bearer " + token };
      await api.patchAxiosCalls("PATCH", "/v1/user/isFree", { isFree: true }, obj);

      try {

        let res = await endCall(data);
        console.log(res);
        otSDK.disconnect();
        stopCursorListener();
        document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
        localStorage.removeItem("vonage_session_id");
        localStorage.removeItem("vonage_token");
        localStorage.removeItem("vonage_meetingId");
        localStorage.removeItem("selected_color");

        // this.setState({ active: false });

        window.location.assign('/dashboard/main');

      } catch (err) {
        console.log("err", err)
        otSDK.disconnect();
        stopCursorListener();
        document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
        // this.setState({ active: false });
        localStorage.removeItem("vonage_session_id");
        localStorage.removeItem("vonage_token");
        localStorage.removeItem("vonage_meetingId");
        localStorage.removeItem("selected_color");
        window.location.assign('/dashboard/main');
      }

    }
  };



  render() {
    const { connected, active, activeChat, annotationStarted, localAudioEnabled, localVideoEnabled, screenShareStarted, screenSharePublishing, disableJoinButton, pointerStarted, emojiPickerOpen, isLeading, localRecordingEnabled, isRecordingInitiator, disableInternalCallButtons, messagesCount } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localScreenShareClass,
      controlClass,
      videoControls,
      cameraPublisherClass,
      screenPublisherClass,
      localRecordingClass,
      cameraSubscriberClass,
      screenSubscriberClass,
      waitingForParticipant,
      localRecordingTimer,
      activeAnnotationButtonClass,
      activeSerialNumberInputData,

    } = containerClasses(this.state);



    return (
      <>
        {/* { !connected && connectingMask() } */}
        {(!connected || (connected && !active)) && startCallMask(this.startCall, localVideoEnabled, this.toggleLocalVideo, localAudioEnabled, this.toggleLocalAudio, isLeading, disableJoinButton)}
        <div id='content' className={(connected && active) ? "video-call-app" : "video-call-app hidden"}>
          <div className="video-calling-screen-wrap">
            <div className={activeChat ? "video-calling-screen-left active" : "video-calling-screen-left"}>
              <div id="video-call-screen-id" className="video-call-screen">
                <div className="video-call-frame">
                  <div id="main-video-container" className="App-video-container">
                    <div id="cameraPublisherContainer" style={{ display: 'none' }} className={cameraPublisherClass} />
                    <div id="screenPublisherContainer" style={{ display: 'none' }} className={screenPublisherClass} />
                    <div id="waitingForParticipantContainer" className={waitingForParticipant} >Waiting for participants to join!</div>
                    {/* <div style={{border: "2px solid blue"}}> */}
                    <div id="cameraSubscriberContainer" className={cameraSubscriberClass} style={{ aspectRatio: "16/9" }} />
                    {/* </div> */}
                    <div id="screenSubscriberContainer" className={screenSubscriberClass} />

                  </div>

                  <div className="video-recorder" id="recording_badge">
                    <div className={localRecordingTimer}>
                      <div className="pulse-dot"></div>
                    </div>
                    <div className="timer" id="timer_counter">00:00:00</div>
                  </div>

                  <div className="participant-name hidden" id="participant_name">
                  </div>

                  {(!screenShareStarted) && <div className={activeAnnotationButtonClass} >
                    {!annotationStarted ? <div className="annotation-cta" id='startAnnotationCustom' onClick={this.screenshot}>
                      <button disabled={disableInternalCallButtons} className="cta-hover"><img src="assets/images/pen-icon.svg" alt="pen-icon" /></button>
                    </div> : <div className="annotation-cta" id='startAnnotationCustom' onClick={this.stopAnnotation}>
                      <button disabled={disableInternalCallButtons} className="cta-hover cta-selected"><img src="assets/images/pen-icon.svg" alt="pen-icon" /></button>
                    </div>}
                    {/* <div className="eraser-cta">
                            <button className="cta-hover" id='OT_undo'><img src="assets/images/eraser-icon.svg" alt="eraser-icon" /></button>
                          </div> */}
                  </div>}

                  {(!(screenShareStarted || annotationStarted)) && <div className="pointer-wrap" >
                    <a href="#" className="pointer-menu-opener" style={{ "background": pointerStarted ? '#197dce' : 'rgba(0, 0, 0, 0.3)' }}>{pointerStarted ? <><img onClick={this.pointerStart} src="assets/images/pointer-white.svg" alt="" /><img className="menu-arrow svg" onClick={this.pointerSelect} src="assets/images/chevron-down.svg" alt="" /></> : <><img onClick={this.pointerStart} src="assets/images/pointer-white.svg" alt="" /><img className="menu-arrow svg" onClick={this.pointerSelect} src="assets/images/chevron-down.svg" alt="" /></>}</a>
                    <ul className="pointer-menu" style={{ display: "block" }}>
                      <li>
                        <a href="#" onClick={() => this.changePointerColor('#0F0F0F')}><img src="assets/images/cursor/0F0F0F.svg" alt="" />Default</a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.changePointerColor('#FFFFFF')}><img src="assets/images/cursor/FFFFFF.svg" alt="" />White</a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.changePointerColor("#FF00F7")}><img src="assets/images/cursor/FF00F7.svg" alt="" />Pink</a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.changePointerColor('#00FFFF')}><img src="assets/images/cursor/00FFFF.svg" alt="" />Turquoise</a>
                      </li>
                    </ul>
                  </div>}

                  <div className="full-screen-wrap hidden">
                    <div className="fullscreen-cta">
                      <button className="cta-hover"><img src="assets/images/full-screen-icon.svg" alt="full-screen-icon" /></button>
                    </div>
                  </div>

                  <div className="screen-capture-wrap hidden">
                    <div className="screen-capture-cta">
                      <button className="cta-hover" disabled={disableInternalCallButtons} onClick={this.takeScreenshot}><img src="assets/images/screen-capture-icon.svg" alt="screen-capture-icon" /></button>
                    </div>
                  </div>
                  <div className="refresh-wrap">
                    <div className="refresh-cta">
                      <button className="cta-hover"><img src="assets/images/refresh-icon.svg" alt="refresh-icon" /></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={videoControls}>
                <div className="control-listing">
                  <ul>
                    <div id="controls" className={controlClass}>
                      <li>
                        <div className="control-cta">
                          <button disabled={!this.state.localPublisherId} className={localAudioClass} onClick={this.toggleLocalAudio}> {localAudioEnabled ? <img src="assets/images/Mic On.svg" alt="microphone-icon" /> : <img src="assets/images/Mic Off.svg" alt="microphone-icon" />}</button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button disabled={!this.state.localPublisherId} className={localVideoClass} disabled={!isLeading} onClick={this.toggleLocalVideo}> {isLeading && localVideoEnabled ? <img src="assets/images/Video On.svg" alt="microphone-icon" /> : <img src="assets/images/Video Off.svg" alt="microphone-icon" />}</button>
                        </div>
                      </li>
                      {/* <li>
                        <div className="control-cta">
                          <button ><img src="assets/images/camera-rotate-icon.svg" alt="camera-rotate-icon" /></button>
                        </div>
                      </li> */}
                      <li>
                        <div className="control-cta end-call">
                          <button className={localRecordingClass} disabled={(localRecordingEnabled && !isRecordingInitiator)} onClick={() => this.toggleRecording()}> {!localRecordingEnabled ? <img src="assets/images/Start Recording.png" alt="" /> : <img src="assets/images/Stop Recording.png" alt="" />} </button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          {!screenSharePublishing ? <button className={localScreenShareClass} disabled={disableInternalCallButtons} onClick={() => this.screenShareToggle()}><img src="assets/images/screen-share-mobile-white.svg" alt="" /></button> : <button className={localScreenShareClass} disabled={disableInternalCallButtons} onClick={() => this.screenShareToggle()}><img src="assets/images/screen-share-mobile.svg" alt="" /></button>}
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button onClick={this.toggleChatWindow} className="chat-cta"><img src="assets/images/message-icon.svg" alt="message-icon" />
                            {<span className="badge" id="badgeIcon" style={{ display: "none" }}>{messagesCount}</span>}
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button onClick={this.takeScreenshot} disabled={disableInternalCallButtons} className="chat-cta">
                            {
                              !disableInternalCallButtons ?
                                <img src="assets/images/screen-capture-icon.svg" alt="message-icon" />
                                :
                                <div className="spinner-border border-white" role="status"></div>
                            }
                          </button>
                        </div>
                      </li>
                      {/* <li>
                        <div className="control-cta">
                          <button  onAuxClick={this.changePointerColor}  className={localPointerClassClass}>  {pointerStarted?<img src="assets/images/pointer-blue.svg" alt="pointer-icon" />:<img src="assets/images/pointer-white.svg" alt="pointer-icon" />} </button>
                        </div>
                      </li> */}
                      {/* <li>
                        <div className="control-cta">
                          <button><img src="assets/images/three-dots.svg" alt="" /></button>
                        </div>
                      </li> */}
                    </div>
                  </ul>
                </div>
                <div className="call-end-control-listing">
                  <ul>
                    <div id="controls" className={controlClass}>
                      <li>
                        <div className="control-cta end-call-cta">
                          <button onClick={() => this.endCallButtonClick()}>
                            {/* <img src="assets/images/three-dots.svg" alt="" />  */}
                            End Call</button>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
                <div className="mobile-control">
                  <ul>
                    <li>
                      <div className="control-cta">
                        <button className={localVideoClass} disabled={!isLeading} onClick={this.toggleLocalVideo}>{(isLeading && localVideoEnabled) ? <img src="assets/images/video-icon-mobile-on.svg" alt="video-icon-mobile" /> : <img src="assets/images/video-icon-mobile-off.svg" alt="video-icon-mobile" />}</button>
                      </div>
                    </li>
                    <li>
                      <div className="control-cta">
                        <button className={localAudioClass} onClick={this.toggleLocalAudio}>{localAudioEnabled ? <img src="assets/images/speaker-icon-on.svg" alt="speaker-icon" /> : <img src="assets/images/speaker-icon-off.svg" alt="speaker-icon" />}</button>
                      </div>
                    </li>
                    <li>
                      <div className="control-cta cut-call">
                        <button className={localRecordingClass} disabled={(localRecordingEnabled && !isRecordingInitiator)} onClick={() => this.toggleRecording()}> {localRecordingEnabled ? <img src="assets/images/Start Recording.png" alt="" /> : <img src="assets/images/Stop Recording.png" alt="" />} </button>
                      </div>
                    </li>
                    <li>
                      <div className="control-cta">
                        <button className={localRecordingClass} onClick={() => this.screenShareToggle()}> {screenSharePublishing ? <img src="assets/images/screen-share-mobile-off.svg" alt="" /> : <img src="assets/images/screen-share-mobile.svg" alt="" />} </button>
                      </div>
                    </li>
                    <li>
                      <div className="control-cta">
                        <button onClick={this.toggleChatWindow} className="chat-cta-mobile"><img src="assets/images/chat-icon.svg" alt="chat-icon" /></button>
                      </div>
                    </li>

                    <li>
                      <div className="control-cta">

                        {((!screenShareStarted && activeSerialNumberInputData) && !annotationStarted) ? <button onClick={this.screenshot}><img src="assets/images/pen-icon-mobile.svg" alt="pen-icon-mobile" /></button> : <button onClick={this.stopAnnotation}><img src="assets/images/pen-icon-mobile.svg" alt="pen-icon-mobile" /></button>}



                      </div>
                    </li>
                    <li>
                      <div className="control-cta cut-call">
                        <button onClick={() => this.endCallButtonClick()}><img src="assets/images/Phone-mobile.svg" alt="phone-icon" /></button>
                      </div>
                    </li>
                    <li>
                      <div className="control-cta">
                        <button className="three-dots"><img src="assets/images/three-dots.svg" alt="three-dots" /></button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* {activeChat?"video-calling-screen-right":"video-calling-screen-right active"} */}
            <div className={activeChat ? "video-calling-screen-right active" : "video-calling-screen-right"}>
              <div className="video-chat-wrap">
                <div className="chat-heading-wrap">
                  <div className="chat-heading">Chat</div>
                  <div className="close-cta">
                    <button className="close-chat" onClick={this.toggleChatWindow}>X</button>
                  </div>
                </div>
                <div id="chatHistory" className="chat-info">

                </div>
              </div>
              <div className="chat-input-wrap">

                <div className="input-group">
                  <FontAwesomeIcon className='smileIcon' size='2x' icon={faSmile} onClick={this.openEmojiBox} />
                  <textarea ref={this.textAreaRef} type="text" className='chatTextAreaInput' placeholder="Type Something..." id="msg_input" onClick={this.closeEmojiBox} onKeyUp={(e) => this.enterPresses(e)} aria-label="Type Something..." aria-describedby="button-send-chat" style={{  overflow: "hidden", resize: "none", overflowY: "auto", border: 'unset', background: 'unset' }} />
                  <EmojiPicker width="100%" height={400} className="emojipicker" open={emojiPickerOpen} searchDisabled={true} autoFocusSearch={false} reactionsDefaultOpen={false} previewConfig={{ showPreview: false }} lazyLoadEmojis={true} onEmojiClick={this.appendEmojiToMessage} />
                  <button className="send-btn" type="button" id="button-send-chat"><img src="assets/images/send-icon.svg" alt="send-icon" onClick={() => this.sendMessage()} /></button>
                </div>
              </div>
            </div>
            <div id="App-screenshot-container" className="App-screenshot-container" />
            <div className="other-mobile-info">
              <div className="mobile-info-close-cta">
                <button className="mobile-info-close">X</button>
              </div>
              <ul>
                <li>
                  <button><img src="assets/images/stop-record-icon.svg" alt="" />Stop Recording</button>
                </li>
                <li>
                  <button><img src="assets/images/screen-share-mobile.svg" alt="screen-share" />Screen Share</button>
                </li>
                <li>
                  <button className="serial-no"><img src="assets/images/barcode.svg" alt="barcode" />Serial Number</button>
                </li>
                <li>
                  <button><img src="assets/images/capture-pic.svg" alt="capture-pic" />Capture Screenshot</button>
                </li>

                {/* <div className="pointer-wrap">
                  <ul className="pointer-menu">
                    <li>
                      <a href="#"><img src="assets/images/pointer-white.svg" className="svg" alt=""/>Pointer<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""/></a>
                      <div className="pointer-sub-menu">
                        <ul>
                          <li><a href="#"><img src="assets/images/pointer-white.svg" alt=""/>small</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="#"><img src="assets/images/hand-mobile.svg" className="svg" alt="">Hand<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""></a>
                      <div className="pointer-sub-menu">
                        <ul>
                          <li><a href="#"><img src="assets/images/pointer-white.svg" alt="asd" />small</a></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div> */}

                {/* <div className="serial-no-mobile">
                    <div className="serial-no-box">
                      <div className="top">
                        <div className="title">Update Serial Number</div>
                        <div className="serial-no-close-cta">
                          <button className="serial-no-close">X</button>
                        </div>
                      </div>
                      <div className="serial-number-wrap">
                        <div className="input-group">
                          <input type="text" placeholder="Enter here..." aria-label="Enter here..." aria-describedby="button-serial-number" />
                          <button className="send-btn" type="button" id="button-serial-number"><img src="assets/images/send-icon.svg" alt="send-icon" /></button>
                        </div>
                      </div>
                    </div>
                </div> */}
              </ul>
            </div>
          </div>
          <div id='screenshot-box' className="screenshot-box ratio ratio-1x1" >
            <img id='screenshot-image' src='' />
          </div>
        </div>

      </>

    );
  }
}

export default App;
