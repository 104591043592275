import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./header";

const API_ENDPOINT = API_URL + "/v1/vonage";

export function vonageStartStopRecording(data) {
  return axios.post(`${API_ENDPOINT}/vonage-start-stop-recording`, data, { headers: getHeaderOptions() });
}

export function vonageStreamActions(data) {
  return axios.post(`${API_ENDPOINT}/vonage-stream-actions`, data, { headers: getHeaderOptions() });
}

export function vonageChangeLayoutClass(data) {
  return axios.post(`${API_ENDPOINT}/change-layout`, data, { headers: getHeaderOptions() });
}
